.history-item {
    padding: 1rem 0;
    border-bottom: 1px #ddd solid;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-end;
    position: relative;
}

.history-item:last-child {
    border-bottom: none;
}

.history-content {
    width: 100%;
    padding-right: 1rem;
}

.history-item p {
    font-size: 1rem;
    margin: 0;
    font-weight: 500;
}

.history-item span {
    font-size: 0.75rem;
    color: #666;
    font-weight: 300;
}

@media screen and (max-width: 30rem) {
    .history-item {
        padding: 0.75rem 0;
    }
}