.translation {
    border-bottom: 1px #ddd solid;
    padding: 1rem 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-end;
    position: relative;
}

.translation:last-child {
    border-bottom: none;
}

.translation-copied {
    position: absolute;
    content: '';
    top: 0.25rem;
    right: 0.25rem;
    font-size: 0;
    color: rgba(222, 222, 222, 0);
    font-weight: 500;
    transition: all 0.333s ease-out;
}

.translation p {
    margin: 0;
    font-size: 0.75rem;
    color: #666;
}

.translation-content {
    width: 100%;
    padding-right: 1rem;
}

.translation-output {
    margin-top: 0.25rem;
    display: block;
    background: rgba(0, 0, 0, 0);
    color: #222;
    min-height: 2.75rem;
    line-height: 1.3;
    font-size: 1.5rem;
    font-family: "NotoSans", 'Roboto', sans-serif;
    font-weight: 400;
    font-optical-sizing: auto;
    font-style: normal;
    white-space: pre;
}

.translation[data-hidden="true"] {
    align-items: center;
    padding: 0.125rem 0;
}

.translation[data-hidden="true"] p {
    color: #999;
}

.translation[data-hidden="true"] .translation-output {
    display: none;
}

.translation[data-hidden="true"] .translation-copy-btn,
.translation[data-hidden="true"] .translation-favorite-btn {
    display: none;
}

.translation[data-copied="true"] .translation-copied {
    font-size: 1rem;
    color: rgba(127, 127, 127, 1);
}

.translation[data-copied="true"] .translation-output {
    border-color: #777;
}


@media screen and (max-width: 30rem) {
    .translation {
        align-items: flex-start;
        flex-wrap: wrap;
        padding: 0.75rem 0;
    }

    .translation-content {
        flex-basis: calc(100vw - 2rem);
        padding-right: 0;
        overflow-x: auto;
        overflow-y: hidden;
        padding-top: 0.75rem;
        position: relative;
    }

    .translation-content p {
        position: sticky;
        top: 0;
        left: 0;
    }

    .translation-output {
        max-width: 100%;
        font-size: 1.25rem;
    }
}