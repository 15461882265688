.history-menu {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-end;
    position: relative;
}

.history-menu h4 {
    flex-grow: 1;
}