main {
    padding: 5rem 1.5rem 1rem 1.5rem;
    width: 100vw;
    height: 100dvh;
    overflow-y: auto;
}

.page {
    min-height: calc(100dvh - 8rem);
}

footer {
    height: 2rem;
    padding: 1rem 1rem 0 1rem;
    font-size: 1rem;
    line-height: 1;
    text-align: right;
    color: #444;
}

footer a {
    color: #777;
    text-decoration: none;
}

footer a:hover {
    color: #092;
}

@media screen and (max-width: 30rem) {

    /* main {
        padding-bottom: 2rem;
    } */
    .page {
        min-height: calc(100dvh - 8.25rem);
    }

    footer {
        height: 1.75rem;
        font-size: 0.75rem;
    }
}