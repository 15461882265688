.ad-block {
    display: block;
    /* margin: 1rem 0; */
}

@media screen and (max-width: 30rem) {
    .ad-block {
        display: block;
        /* padding: 0.75rem 0; */
    }
}