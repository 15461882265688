.saved-item {
    padding: 1rem 0;
    border-bottom: 1px #ddd solid;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-end;
    position: relative;
}

.saved-item:last-child {
    border-bottom: none;
}

.saved-content {
    width: 100%;
    padding-right: 1rem;
}

.saved-item p {
    margin: 0;
    color: #222;
    line-height: 1.3;
    font-size: 1.5rem;
    font-family: "NotoSans", 'Roboto', sans-serif;
    font-weight: 400;
    white-space: nowrap;

}

.saved-item span {
    line-height: 1rem;
    font-size: 0.75rem;
    color: #666;
}

@media screen and (max-width: 30rem) {
    .saved-item {
        padding: 0.75rem 0;
        align-items: flex-start;
        flex-wrap: wrap;
    }

    .saved-content {
        flex-basis: calc(100vw - 2rem);
        padding-right: 0;
        padding-top: 1rem;
        overflow-x: auto;
        overflow-y: hidden;
        position: relative;
    }

    .saved-item span {
        position: sticky;
        top: 0;
        left: 0;
    }

    .saved-item p {
        font-size: 1.25rem;
    }
}