.settings-number {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem 0;
}

.settings-number label {
    display: block;
    width: 10rem;
    font-size: 1rem;
    color: #777;
}

.settings-number input {
    border: 1px rgba(0, 0, 0, 0.25) solid;
    padding: 0.125rem;
    font-size: 1rem;
    color: #444;
}

.settings-number input[type="number"] {
    width: 5rem;
}

.settings-number input[type="text"] {
    width: 25rem;
}