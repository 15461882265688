*,
*::before,
*::after {
    box-sizing: border-box;
}

html,
body {
    font-size: 16px;
    height: 100dvh;
    width: 100vw;
    overflow: hidden;
}

body {
    margin: 0;
    font-family: 'Roboto', 'San Francisco', 'Helvetica Neue', 'Tahoma', sans-serif;
}

.page-inner {
    width: 100vw;
    height: 100dvh;
}