.navigation a {
    text-decoration: none;
    color: #fff;
    padding: 0.5rem 1rem;
}

.navigation a:hover {
    color: #ddd;
    background: rgba(255, 255, 255, 0.1);
}

.navigation a.active,
.navigation a.active:hover {
    color: #fff;
    font-weight: 500;
    background: rgba(255, 255, 255, 0.2);
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.6);
    border-radius: 0.25rem;
}

.drawer a {
    text-decoration: none;
    color: #222;
}

.drawer a:hover {
    text-decoration: underline;
}

.drawer a.active,
.drawer a.active:hover {
    outline: 1px #444 solid;
    outline-offset: 0.25rem;
}