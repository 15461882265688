/* .settings-menu {} */

.settings-menu h4 {
    user-select: none;
}

.settings-menu small {
    font-size: 1rem;
    color: #f20;
    margin-left: 1rem;
    position: relative;
    top: -0.15rem;
}

.settings-controls {
    padding: 1rem 0;
    display: flex;
    gap: 1rem;
}

.settings-info p {
    font-size: 0.875rem;
    margin: 0 0 0.25rem 0;
}

.settings-info {
    padding-bottom: 1rem;
}

.settings-note {
    display: inline-block;
    font-size: 1rem;
    color: #444;
    line-height: 1;
    background: rgba(0, 0, 0, 0.03);
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0.75rem 0.85rem;
}

.settings-note::before {
    content: 'Note:';
    font-weight: 500;
    margin-right: 0.5rem;
}

.settings-note code {
    color: #666;
}

/* .settings-entries {} */