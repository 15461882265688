.input-field {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.5rem;
}

.input-clear {
    position: relative;
    top: 0.5rem;
}

.input-clear:disabled {
    opacity: 0;
}

/* .input-text-field {} */